<template>
  <div class="page poet">
    <div class="details" v-if="info.length>0">
      <div class="poemContent">
        <div class="tx"><i><img :src="getTx(info[0].poet)"> </i></div>
        <div class="text">
          <div class="poemT f56">{{info[0].poet}}</div>
          <div class="content f18" v-html="info[0].content"></div>
          <div class="live f14">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="lucide lucide-calendar h-5 w-5 text-muted-foreground">
              <rect width="18" height="18" x="3" y="4" rx="2" ry="2"></rect>
              <line x1="16" x2="16" y1="2" y2="6"></line>
              <line x1="8" x2="8" y1="2" y2="6"></line>
              <line x1="3" x2="21" y1="10" y2="10"></line>
            </svg>
            {{info[0].live}}
          </div>
        </div>
      </div>
        <div class="tags">
          <ul>
            <li class="cur">{{info[0].dynasty}}</li>
            <li v-for="(item,xh) in tags" :key="xh">{{item}}</li>
          </ul>
        </div>
      <div class="poemNote">
        <div class="httitle f22">作品列表<i>{{getTotal(info[0].poet)}}</i></div>
        <div class="content f14">
          <div v-for="(item,xh) in getPoem" :key="xh">
            <div class="httitle f18">{{item.letter}}<i class="f12">({{item.sc.length}})</i></div>
            <ul class="list">
              <router-link tag="li" :to="'/poem/'+it[0].id" v-for="(it,inx) in item.sc" :key="inx">{{it[0].poem}}
              </router-link>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import {detail} from '@/api/api'
import {pinyin} from 'pinyin-pro'

export default {
    name: 'poet',
    data() {
        return {
            poet: [],
            poem: []
        }
    },
    created() {
        let formData3 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poem',
            perpage: 10000,
            order: ["id DESC"]
        }
        let formData4 = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'poet',
            perpage: 10000,
            order: ["id DESC"]
        }

        if (this.$store.state.articles.poem.length === 0) {
            this.$store.dispatch('articles/getList', formData3).then((rs) => {
                this.poem = rs

            })
        } else {
            this.poem = this.$store.state.articles.poem
        }
        if (this.$store.state.articles.poet.length === 0) {
            this.$store.dispatch('articles/getList', formData4).then((rs) => {
                this.poet = rs
            })
        } else {
            this.poet = this.$store.state.articles.poet
        }

    },
    watch: {
        $route: {
            handler() {

            },
            immediate: true
        }
    },
    methods: {
        getTotal(text) {
            let fi = this.poem.filter((el) => {
                return el.poet === text
            })
            return fi.length
        },
        getTx(name){
            let text = pinyin(name, {toneType: 'none'})
            text=text.replace(/\s/g, "")
            let url='https://ziyuan.guwendao.net/authorImg300/'+text+'.jpg'
            return url

        }
    },
    computed: {
        getPoem() {
            let fi = this.poem.filter((el) => {
                return el.poet == this.info[0].poet
            })
            fi.sort((a, b) => {
                let value1 = a.poem,
                    value2 = b.poem;
                return value1.localeCompare(value2, "zh")

            });
            let zuihou = []
            let last = []
            let zm = []
            fi.forEach((el) => {
                let f = pinyin(el.poem, {pattern: 'first', type: 'array'})[0]
                f = f.toUpperCase()
                let sc = []
                zm.push(f)
                sc.push(el)
                last.push({letter: f, sc: sc})
            })
            let lzm = new Set(zm)
            lzm.forEach((el) => {
                let fi = last.filter((elm) => {
                    return el == elm.letter
                })
                let sc = []
                fi.forEach((fx) => {
                    sc.push(fx.sc)
                })

                zuihou.push({letter: el, sc: sc})

            })
            return zuihou
        },
        tags() {
            let tags = this.info[0].tags
            let tag = []
            if (tags) {
                if (tags.indexOf('|') > -1) {
                    tag = tags.split("|")
                } else {
                    tag.push(tags)
                }

            }
            return tag

        },
        info() {
            let id = this.$route.params.id
            let fi = this.poet.filter((el) => {
                return el.id == id
            })
            return fi

        },
    }
}
</script>

